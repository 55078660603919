<template>

  <b-modal
    v-model="safeShow"
    size="lg"
    centered
    hide-footer
    id="modal-log">
    <template slot="modal-header-close">&nbsp;</template>

    <table class="table table-hover table-outline table-vcenter card-table">
      <thead>
        <tr>
          <th>Field</th>
          <th class="w-1">Old value</th>
          <th class="w-1"></th>
          <th>New value</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="change in changes" :key="change.field">
          <td class="font-weight-bold text-nowrap">{{change.field}}</td>
          <td>{{change.oldValue}}</td>
          <td><i class="fe fe-arrow-right"></i></td>
          <td>{{change.newValue}}</td>
        </tr>
      </tbody>
    </table>
  </b-modal>

</template>

<script>

export default {
  props: [

    'show',
    'value',
  ],

  computed: {

    changes() {
      let formatted = [];

      if (this.value) {
        formatted = Object.entries(this.value.attributes).map(attr => {
          return {

            field: attr[0],
            newValue: attr[1],
            oldValue: this.value.old ? this.value.old[attr[0]] : 'N/A',
          };
        });
      }

      return formatted;
    },
    safeShow: {

      get() { return this.show; },
      set(newvalue) {
        this.$emit('update:show', newvalue);
      },
    },
  },
};

</script>

<style>

    #modal-log .modal-body {
        padding: 0;
        overflow: auto;
    }

</style>
