<template>
  <form @submit.prevent="applyFilter()">
    <div class="row">
      <div class="col col-4">
        <div class="form-group">
          <label for="id">User:</label>
          <v-select
            v-model="filter.user"
            label="id"
            :filterable="false"
            :options="customers"
            @search="searchCustomer">
            <template slot="no-options">
              Type email to search users...
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{option.user.email}}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{option.user.email}}
              </div>
            </template>
          </v-select>
        </div>
      </div>
      <div class="col col-4">
        <div class="form-group">
          <label for="datetime">Date/Time From:</label>
          <calendar-day-picker v-model="filter.calendarFrom" class="mb-0"></calendar-day-picker>
        </div>
      </div>
      <div class="col col-4">
        <div class="form-group">
          <label for="datetime">Date/Time To:</label>
          <calendar-day-picker v-model="filter.calendarTo" class="mb-0"></calendar-day-picker>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col" :class="filter.model ? 'col-4' : 'col-6'">
        <div class="form-group">
          <label for="model">Model:</label>
          <select
            v-model="filter.model"
            class="custom-select form-control"
            id="model">
            <option value="">Any</option>
            <option
              v-for="model in models"
              :key="model.name"
              :value="model.name">
              {{model.name}}
            </option>
          </select>
        </div>
      </div>
      <div
        v-if="filter.model"
        class="col"
        :class="filter.model ? 'col-4' : 'col-6'">
        <div class="form-group">
          <label for="model">Attributes:</label>
          <select
            v-model="filter.attribute"
            class="custom-select form-control"
            id="model">
            <option value="">Any</option>
            <option
              v-for="attribute in attributes"
              :key="attribute"
              :value="attribute">
              {{attribute}}
            </option>
          </select>
        </div>
      </div>
      <div class="col" :class="filter.model ? 'col-4' : 'col-6'">
        <div class="form-group">
          <label for="search">Query:</label>
          <input
            v-model="filter.query"
            type="search"
            class="form-control"
            placeholder="Search query"
            id="search">
        </div>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button class="form-control btn btn-primary">Apply</button>
      </div>
    </div>
  </form>
</template>

<script>
import CalendarDayPicker from '@/components/CalendarDayPicker';
import {activitylogModels, customers} from '@/services';
import debounce from 'lodash/debounce';
import moment from 'moment-timezone';

export default {
  components: {
    CalendarDayPicker,
  },
  data() {
    return {
      customers: [],
      filter: {
        attribute: '',
        calendarFrom: {
          confirm: true,
          format: 'D MMM YY h:mm A',
          startDate: moment().startOf('week'),
          type: 'datetime',
        },
        calendarTo: {
          confirm: true,
          format: 'D MMM YY h:mm A',
          startDate: moment().endOf('day'),
          type: 'datetime',
        },
        model: '',
        query: '',
        user: null,
      },
      models: [],
    };
  },
  computed: {
    attributes() {
      return this.filter.model ? this.models.find(item => item.name === this.filter.model).attributes : [];
    },
  },
  created() {
    this.searchCustomer = debounce(async (search, loading) => { await this.fetchUsers(search, loading); }, 500);

    activitylogModels.getByParameters().then(results => this.models = results);
  },
  methods: {
    applyFilter() {
      this.$emit('filter', this.filter);
    },
    async fetchUsers(search, loading) {
      if (search.length) {
        loading(true);

        const results = await customers.getByParameters({limit: this.limit, query: search});

        this.customers = results.items;

        loading(false);

        return true;
      }
    },
  },
};
</script>
