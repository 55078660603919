<template>
  <div class="container">
    <div class="page-header">
      <span v-if="!query" class="text-truncate">Showing {{items.length}} of {{meta.total}} logs</span>
      <span v-else class="text-truncate">Found {{items.length}} logs that match '{{query}}'</span>
    </div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <log-filters @filter="(data) => applyFilter(data)"></log-filters>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <table class="table table-hover table-outline table-vcenter card-table">
              <thead>
                <tr>
                  <th>Log name</th>
                  <th>Causer</th>
                  <th>Date / Time</th>
                  <th>Description</th>
                  <th>Model</th>
                  <th>Model ID</th>
                  <th class="w-1"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td class="text-nowrap">{{item.logName}}</td>
                  <td class="text-nowrap">
                    <a
                      v-if="item.causer"
                      target="_blank"
                      :href="`/users/${item.causer.customer_id}`">{{truncate(displayName(item.causer))}}</a><span
                        v-else>N/A</span>
                  </td>
                  <td class="text-nowrap">{{moment(item.createdAt).format('ddd, D MMM YYYY hh:mm A')}}</td>
                  <td>{{item.description}}</td>
                  <td class="text-nowrap">
                    <!-- <a :href="item.route" v-if="item.route" target="_blank">{{item.subject_type}}</a> -->
                    {{item.subject_type}}
                  </td>
                  <td class="text-nowrap">
                    {{item.subject_id}}
                  </td>
                  <td class="text-nowrap">
                    <a
                      v-if="item.changes"
                      class="link"
                      href="#"
                      @click="openDetailModal(item.changes)">View
                      ({{Object.keys(item.changes.attributes).length}} changes)</a>
                    <span v-else>No Changes</span>
                  </td>
                </tr>
                <tr v-if="items.length === 0">
                  <td colspan="6">There are no results matching your criteria.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>
    </div>

    <log-detail-modal v-model="selectedChanges" :show.sync="showLogDetailModal"></log-detail-modal>
  </div>
</template>

<script>
import LogDetailModal from '@/components/LogDetailModal';
import Pagination from '@/components/Pagination';
import list from '@/mixins/list';
import {activitylogs} from '@/services';
import moment from 'moment-timezone';
import LogFilters from './LogFilters';

export default {
  components: {
    LogDetailModal,
    LogFilters,
    Pagination,
  },
  mixins: [
    list,
  ],
  data() {
    return {
      filter: null,
      models: [],
      selectedChanges: null,
      showLogDetailModal: false,
    };
  },
  methods: {
    applyFilter(filter) {
      this.filter = filter;

      this.goToPage(1);
      this.loadPage(1);
    },
    fetchData(page) {
      const params = {
        limit: this.limit,
        page,
      };
      if (this.filter) {
        Object.assign(params, {

          attributeName: this.filter.attribute,
          endDate: moment(this.filter.calendarTo.startDate).format('YYYY-MM-DD hh:mm:ss'),
          q: this.filter.query,
          startDate: moment(this.filter.calendarFrom.startDate).format('YYYY-MM-DD hh:mm:ss'),
          subject_type: this.filter.model,
          userId: this.filter.user ? this.filter.user.user.id : null,
        });
      }
      return activitylogs.getByParameters(params);
    },
    openDetailModal(changes) {
      this.selectedChanges = changes;
      this.showLogDetailModal = true;
    },
  },
};
</script>
